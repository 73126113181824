<template>
	<v-app>
		<v-slide-y-transition mode="out-in">
			<v-alert
				id="alert"
				v-show="alert.show"
				max-width="350px"
				dense
				:type="alert.type"
				>{{ alert.message }}</v-alert
			>
		</v-slide-y-transition>

		<PleaseWait v-if="wait" />

		<v-main v-else id="home-page">
			<v-container>
				<v-row justify="center" no-gutters>
					<v-col>
						<v-form ref="form" v-model="valid" lazy-validation>
							<v-card class="mx-auto login-user">
								<v-card-text>
									<v-img
										:src="require('../assets/logo-acm.png')"
										class="my-3"
										contain
										height="92"
									/>

									<v-text-field
										v-model="email"
										label="Email"
										prepend-inner-icon="mdi-account"
										type="email"
										outlined
										:rules="[
											v =>
												!!v ||
												'Este campo es obligatorio',
										]"
									/>

									<v-text-field
										id="password"
										label="Contraseña"
										v-model="password"
										prepend-inner-icon="mdi-lock"
										:type="fieldTypePassword"
										placeholder="ej: $u-C1av3-Aqu1"
										outlined
										:rules="passRules"
										:append-icon="iconFieldTypePassword"
										@click:append="
											showPassword = !showPassword
										"
									/>
								</v-card-text>

								<v-card-actions>
									<v-btn
										class="primary"
										block
										@click="formToLoginUser()"
										:disabled="disabled"
									>
										Entrar
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-form>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
	import PleaseWait from '@/components/PleaseWait';

	import { mapActions } from 'vuex';

	export default {
		name: 'login-page',

		data() {
			return {
				email: null,
				password: null,
				disabled: false,
				register: false,

				alert: {
					type: 'error',
					message: '',
					show: false,
				},

				alerts: {
					'Auth.form.error.blocked':
						'Su cuenta ha sido bloqueada por un administrador.',
					'Auth.form.error.invalid':
						'Usuario o contraseña inválidos.',
				},

				wait: false,

				valid: true,
				passRules: [
					v => !!v || 'Este campo es obligatorio',
					v =>
						(v && v.length >= 8) ||
						'La contraseña debe ser mayor o igua a 8 caracteres',
				],
				showPassword: false,
			};
		},

		computed: {
			fieldTypePassword() {
				return this.showPassword ? 'text' : 'password';
			},
			iconFieldTypePassword() {
				return this.showPassword ? 'mdi-eye' : 'mdi-eye-off';
			},
		},

		components: {
			PleaseWait,
		},

		methods: {
			...mapActions('userControl', ['loginUser']),

			showAlert(code, message, type) {
				this.alert = {
					type: type,
					message: this.alerts[code] ? this.alerts[code] : message,
					show: true,
				};

				setTimeout(() => {
					this.alert.show = false;
				}, 5000);
			},

			formToLoginUser() {
				if (this.$refs.form.validate()) {
					this.wait = true;

					this.loginUser({
						email: this.email,
						password: this.password,
					})
						.then(() => {
							this.$router.push({ name: 'dashboard' });
						})
						.catch(error => {
							this.wait = false;
							this.showAlert(error.id, error.message, 'error');
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.login-user {
		margin-top: 30vh;
		max-width: 400px;
	}

	#alert {
		position: absolute;
		top: 10px;
		right: 10px;
	}
</style>
