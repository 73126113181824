<template>
	<div class="container">
		<div class="loader-container">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'wait-page',
	};
</script>

<style lang="scss" scoped>
	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.loader-container {
		width: 50%;
		height: 50%;
		position: relative;
		filter: url(#gooey);
	}

	.loader-container > * {
		position: absolute;
		display: inline-block;
		left: 0;
		width: 4rem;
		height: 4rem;
		background: #c0dfff;
		top: 25%;
		border-radius: 50%;
		animation: loading 4s infinite;
		transform: scale(0.1);
		opacity: 0;

		&:nth-child(1) {
			animation-delay: 0.5s;
		}
		&:nth-child(2) {
			animation-delay: 1s;
		}
		&:nth-child(3) {
			animation-delay: 1.5s;
		}
		&:nth-child(4) {
			animation-delay: 2s;
		}
	}

	@keyframes loading {
		50% {
			transform: scale(1.25);
			left: 50%;
			opacity: 1;
		}
		100% {
			transform: scale(0.1);
			left: 100%;
			opacity: 0;
		}
	}
</style>
